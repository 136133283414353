$(document).ready(function() {
	$('body').on('custom-uploader', 'input[data-questionnaire]', function (e, files) {
		const parts = $(this).attr('name').match(/^option\-(\d+)\[\]$/);
		if (null == parts) {
			return;
		}
		const id = parts[1];
		const $parent = $(this).closest('.custom-uploader');
		const $info = $parent.find('.upload-filename');
		const $spans = $info.find('span');
		$spans.each(function(i, span) {
			const $xmark = $($('#times-square').html());
			$xmark.data('id', id);
			$(this).append($xmark);
		});

		if (false == $(this).prop('multiple')) {
			const $container = $(this).closest('[data-questionnaire-container]');
			const $select = $container.find(`select[name="attachment-${id}"]`);
			if ($select.length) {
				$select[0].sumo.disable();
			}
		}
	});

	$('body').on('click', 'a[data-questionnaire]', function (e) {
		const id = $(this).data('id');
		const $parent = $(this).closest('.custom-uploader');
		const $info = $parent.find('.upload-filename');
		const $spans = $info.find('span');
		const $span = $(this).parent();
		const index = $spans.index($span);
		const $file = $parent.find('input[type="file"]');

		// Remove the file from the DataTransfer object
		let existingFiles = $file.data('existingFiles') || [];
		existingFiles.splice(index, 1);
		$file.data('existingFiles', existingFiles);

		// Update the input with the new file list
		let dataTransfer = new DataTransfer();
		existingFiles.forEach(file => dataTransfer.items.add(file));
		$file.get(0).files = dataTransfer.files;

		// Remove the file from the UI
		$span.remove();
		$parent.find('.upload-dragdrop').removeClass('dragover');

		if ($file.get(0).files.length == 0) {
			const $container = $file.closest('[data-questionnaire-container]');
			const $select = $container.find(`select[name="attachment-${id}"]`);
			if ($select.length) {
				if (false == $select.prop('multiple')) {
					$select[0].sumo.enable();
				}
			}
		}
	});

	$('body').on('change', '.custom-uploader input[type=file]', function() {
		const parentDiv = $(this).parent().closest('.custom-uploader');
		const uploadFilename = parentDiv.find('.upload-filename');
		if (uploadFilename.length) {
			if ($(this).prop('multiple')) {
				// Retrieve existing files from data attribute
				let existingFiles = $(this).data('existingFiles') || [];
				let newFiles = Array.from(this.files);
				
				// Merge existing and new files
				let combinedFiles = existingFiles.concat(newFiles);

				// Update the input with the combined file list
				let dataTransfer = new DataTransfer();
				combinedFiles.forEach(file => dataTransfer.items.add(file));
				this.files = dataTransfer.files;

				// Store combined files
				$(this).data('existingFiles', combinedFiles);

				// Update UI
				uploadFilename.empty();
				combinedFiles.forEach(file => {
					let span = $(`<span></span>`);
					span.text(file.name);
					uploadFilename.append(span);
				});
			} else {
				uploadFilename.empty();
				for (var i = 0; i < this.files.length; i++) {
					let span = $(`<span></span>`);
					span.text(this.files.item(i).name);
					uploadFilename.append(span);
				}
			}
		}
		$(this).trigger('custom-uploader', [this.files]);
	});

	$('body').on('dragenter', '.custom-uploader input[type=file]', function() {
		let parentDiv = $(this).parent().closest('.custom-uploader');
		parentDiv.find('.upload-dragdrop').addClass('dragover');
	});

	$('body').on('dragleave', '.custom-uploader input[type=file]', function() {
		let parentDiv = $(this).parent().closest('.custom-uploader');
		parentDiv.find('.upload-dragdrop').removeClass('dragover');
	});

	// Handle file drop
	$('body').on('drop', '.custom-uploader input[type=file]', function(event) {
		event.preventDefault();
		let parentDiv = $(this).parent().closest('.custom-uploader');
		parentDiv.find('.upload-dragdrop').removeClass('dragover');
		
		let files = event.originalEvent.dataTransfer.files;
		let $fileInput = $(this);
		let existingFiles = $fileInput.data('existingFiles') || [];
		let newFiles = Array.from(files);

		// Merge existing and new files
		let combinedFiles = existingFiles.concat(newFiles);

		// Update the input with the combined file list
		let dataTransfer = new DataTransfer();
		combinedFiles.forEach(file => dataTransfer.items.add(file));
		$fileInput.get(0).files = dataTransfer.files;

		// Store combined files
		$fileInput.data('existingFiles', combinedFiles);

		// Update UI
		let uploadFilename = parentDiv.find('.upload-filename');
		uploadFilename.empty();
		combinedFiles.forEach(file => {
			let span = $(`<span></span>`);
			span.text(file.name);
			uploadFilename.append(span);
		});

		$fileInput.trigger('custom-uploader', [combinedFiles]);
	});

	// Prevent default behavior for dragover and drop
	$('body').on('dragover drop', '.custom-uploader input[type=file]', function(e) {
		e.preventDefault();
	});
});
