document.originalCreateElement = document.createElement;
document.createElement = function(tagname, options)
{
    const node = document.originalCreateElement.call(document, tagname, options);
    if (tagname.toLowerCase() != 'script') {
        return node;
    }
    Object.defineProperty(node, 'src', {
        set: function(value)
        {
            value = value
                .toString()
                .replace(
                    'maps.googleapis.com/maps/vt',
                    'europe-west2-zatpermit.cloudfunctions.net/maps-cache'
                );
            this.setAttribute('src', value);
        }
    });
    return node;
}
