const filters = {
   types: {documents: false, images: false},
};

/**
 *
 */
function initialiseControls()
{
    // Filters
    const querystring = new URLSearchParams(window.location.search);
    const filters = {
        car: querystring.get('documents') || localStorage.getItem('MyDocuments_filters_documents'),
        van: querystring.get('images') || localStorage.getItem('MyDocuments_filters_images'),
    }
    for (const [key, value] of Object.entries(filters)) {
        const checked = value == 1;
        $(`input[name="${key}-filter"]`).prop('checked', checked);
        if (checked) {
            toggleFilter(key, checked);
        }
    }

    // Sort
    const order = querystring.get('order') || localStorage.getItem('MyDocuments_sort_order') || 'created|1';
    $('select[name="my-documents-sort"]').val(order).change();
    localStorage.setItem('MyDocuments_sort_order', order);

    $('#attachments-grid').show();
    Foundation.reInit('equalizer');
}

/**
 *
 */
function toggleFilter(key = null, checked = false)
{
    if (['documents', 'images'].includes(key)) {
        filters.types[key] = checked;
    }

    showOrHide();
}

/**
 *
 */
function showOrHide()
{
    var $documents = $('div.document');
    $documents.hide();

    for (const [name, options] of Object.entries(filters)) {
        const checked = Object.values(options).filter(v => v);
        if (checked.length && checked.length != Object.values(filters.types).length) {
            const attributes = [];
            for (const [filter, value] of Object.entries(options)) {
                if (value) {
                    attributes.push(`[data-${filter}]`)
                    localStorage.setItem(`MyDocuments_filters_${filter}`, 1);
                } else {
                    localStorage.removeItem(`MyDocuments_filters_${filter}`);
                }
            }
            $documents = $documents.filter(attributes.join(','));
        } else {
            for (const [filter, value] of Object.entries(options)) {
                localStorage.removeItem(`MyDocuments_filters_${filter}`);
            }
        }
    }

    $documents.show();
}

$(document).ready(function () {

    if ($('#attachments-grid').length == 0) {
        return;
    }

    $('body')
        // Filter Checkbox
        .on('click', 'input.filter', function() {
            var key = $(this).attr('name').replace('-filter', '');
            const checked = $(this).is(':checked');
            toggleFilter(key, checked);
        })
        // Sort Dropdown
        .on('change', 'select[name="my-documents-sort"]', function() {
            const value = $(this).val();
            localStorage.setItem('MyDocuments_sort_order', value);
            const [order, direction] = value.split('|');
            const offset = [[-1, 1], [1, -1]];

            var $documents = $('div.document');
            const documents = [];
            $documents.each(function() {
                documents.push({id: $(this).attr('id'), order: $(this).data(order)})
            });
            documents.sort((a, b) => {
                if (a.order == b.order) {
                    return 0;
                }
                return a.order < b.order ? offset[direction][0] : offset[direction][1];
            });

            const $div = $('<div></div>');
            documents.forEach(doc => {
                $div.append($(`#${doc.id}`))
            });

            $('#attachments-grid').append($div.html());

            $('#attachments-grid').find('.dropdown-pane').each(function(k, v) {
                new Foundation.Dropdown($(this));
            });
        })
        .on('redraw.documents', function() {
            showOrHide();
            $('select[name="my-documents-sort"]').change();
        });

    // Load control state from querystring or localStorage
    initialiseControls();
});

